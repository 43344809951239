import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'wouter';

import MainLayout from './layouts/Main';

import '../styles/styles.scss';
import ViewerPage from './pages/Viewer';

const propTypes = {
    micromag: PropTypes.shape({})
};

const defaultProps = {
    micromag: null
};

function Routes({ micromag }) {
    return (
        <Switch>
            <MainLayout>
                <Route path="/:screen?">
                    {({ screen }) => <ViewerPage micromag={micromag} baseUrl='/' screen={screen} />}
                </Route>
            </MainLayout>
        </Switch>
    );
}

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
