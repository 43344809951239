import { RoutesProvider } from '@folklore/routes';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from 'wouter';
import { getJSON } from '@folklore/fetch';

import Routes from './Routes';

const propTypes = {
    intl: PropTypes.shape({
        locale: PropTypes.string,
        messages: PropTypes.oneOfType([
            PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
            PropTypes.objectOf(PropTypes.string),
        ]),
    }),
    routes: PropTypes.objectOf(PropTypes.string),
};

const defaultProps = {
    intl: null,
    routes: {},
};

function App({ intl, routes, micromagUrl }) {
    const [micromag, setMicromag] = useState(null)

    const { locale = 'fr', messages = {} } = intl || {};
    const finalMessages = (
        messages !== null && messages[locale]
        ? messages[locale]
        : messages
    );


    useEffect(() => {
        getJSON(micromagUrl).then(data => setMicromag(data))
    }, [micromagUrl])

    return (
        <IntlProvider locale={locale} defaultLocale="fr" messages={finalMessages || {}}>
            <Router>
                <RoutesProvider routes={routes}>
                    <Routes micromag={micromag} />
                </RoutesProvider>
            </Router>
        </IntlProvider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
