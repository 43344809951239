import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'wouter';

import Micromag from '../partials/Micromag';

import styles from '../../styles/pages/viewer.module.scss';

const propTypes = {
    micromag: PropTypes.shape({}),
    baseUrl: PropTypes.string,
    screen: PropTypes.string
};

const defaultProps = {
    micromag: null,
    baseUrl: '/',
    screen: null
};
function ViewerPage({ micromag, baseUrl, screen }) {
    const [, setLocation] = useLocation();
    const { components: screens = [] } = micromag || {};

    const pathScreenId = screen;
    const pathScreenIndex =
        pathScreenId !== null && pathScreenId.match(/^[0-9]+$/) !== null
            ? parseInt(pathScreenId, 10) - 1
            : null;
    const screenByPathId =
        pathScreenId !== null
            ? screens.find(({ id: screenId }) => screenId === pathScreenId) || null
            : null;
    const screenByPathIndex = pathScreenIndex !== null ? screens[pathScreenIndex] || null : null;
    const currentScreen =
        screenByPathId ||
        screenByPathIndex ||
        (pathScreenId === '' ? screens[0] : null) ||
        screens[0] ||
        null;

    const onScreenChange = useCallback(
        (newScreen) => {
            const newScreenIndex = screens.findIndex(({ id: screenId }) => screenId === newScreen.id);
            setLocation(
                newScreenIndex >= 0
                ? `/${newScreenIndex + 1}`
                : `/1`,
            );
        },
        [screens, setLocation],
    );

    if (!micromag) {
        return null
    }

    return (
        <div className={styles.container}>
            <Micromag
                micromag={micromag}
                locale='fr'
                basePath={baseUrl}
                currentScreenId={currentScreen !== null ? currentScreen.id : null}
                className={styles.micromag}
                onScreenChange={onScreenChange}
            />
        </div>
    );
}

ViewerPage.propTypes = propTypes;
ViewerPage.defaultProps = defaultProps;

export default ViewerPage;
